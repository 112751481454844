import * as React from "react";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import styled from "styled-components";

const Page = styled.div``;

const NotFoundPage = () => {
  return (
    <Page>
      <Hero text="Page Not Found" />
      
      <Layout title={"Page Not Found"}>
        <Footer />
      </Layout>
    </Page>
  );
};

export default NotFoundPage;
